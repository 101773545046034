.section-automation {
  padding: 71px 0;
  @media (max-width: 1440px) {
    padding: 48px 0;
  }
  @media (max-width: 1366px) {
    padding: 52px 0;
  }
  @media (max-width: 768px) {
    padding: 33px 0;
  }
  .row {
    align-items: center;
  }
}
