header {
  padding: 19px 0;
  transition: all 0.3s ease;
  @media (max-width: 1440px) {
    padding: 13px 0;
  }
  @media (max-width: 991px) {
    position: fixed;
    width: 100%;
    height: calc(100% - 80px);
    transform: translateX(0);
    top: 80px;
    left: 100%;
    background-color: $primary;
  }
}
.nav-left {
  display: flex;
  align-items: center;
  .logo-box {
    margin-right: 60px;
    @media (max-width: 1440px) {
      margin-right: 44px;
    }
    @media (max-width: 1366px) {
      margin-right: 39px;
    }
    @media (max-width: 991px) {
      positin: absolute;
      top: 20px;
      display: none;
    }
    .image-container {
      width: 103px;
      @media (max-width: 1440px) {
        width: 77px;
      }
      @media (max-width: 1366px) {
        width: 72px;
      }
    }
  }
  .nav-container {
    @media (max-width: 991px) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .nav-list {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      @media (max-width: 991px) {
        flex-direction: column;
      }
      .nav-item {
        &:not(:last-child) {
          margin-right: 35px;
          @media (max-width: 1440px) {
            margin-right: 26px;
          }
          @media (max-width: 1366px) {
            margin-right: 22px;
          }
          @media (max-width: 991px) {
            margin-right: 0px;
            margin-bottom: 22px;
          }
        }
        a {
          color: $white;
          font-size: 16px;
          font-weight: 400;
          text-decoration: none;
          position: relative;
          &:before {
            position: absolute;
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $text-sub;
            bottom: -40px;
            transform: scaleX(0);
            transform-origin: left;
            transition: all 0.3s cubic-bezier(0.35, 1.77, 0.97, 0.83);
            @media (max-width: 1919px) {
              bottom: -39px;
            }
            @media (max-width: 1440px) {
              bottom: -31px;
            }
            @media (max-width: 991px) {
              bottom: -8px;
            }
          }
          &:hover {
            &:before {
              transform: scaleX(1);
            }
          }
          @media (max-width: 1440px) {
            font-size: 12px;
          }
        }
      }
      .nav-item.active {
        a {
          &:before {
            transform: scaleX(1);
          }
        }
      }
    }
  }
}

header.is-sticky {
  @media (max-width: 991px) {
    box-shadow: none;
  }
  .nav-container {
    .nav-list {
      .nav-item {
        a {
          &:before {
            background-color: $primary;
          }
        }
      }
    }
  }
}

.nav-right {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 991px) {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    justify-content: center;
  }
  .button-group {
    display: flex;
    .button-container {
      &:not(:last-child) {
        margin-right: 20px;
        @media (max-width: 1366px) {
          margin-right: 12px;
        }
      }
    }
  }
}

.mobile-header {
  position: absolute;
  top: 0;
  padding: 15px 0;
  z-index: 99;
  width: 100%;
  display: none;
  @media (max-width: 991px) {
    display: block;
  }
  .logo-dark {
    display: none;
  }
  .image-container {
    width: 103px;
  }
}
.mobile-header.is-sticky {
  position: fixed;

  .logo-dark {
    display: block;
  }
  .logo-normal {
    display: none;
  }
  .hamburger {
    label {
      span {
        background: $primary;
      }
    }
  }
}
.mobile-header.is-sticky.show-nav {
  @media (max-width: 991px) {
    box-shadow: none;
  }
}

.hamburger {
  display: flex;
  justify-content: flex-end;
  display: none;
  @media (max-width: 991px) {
    display: flex;
  }
  label {
    display: flex;
    flex-direction: column;
    width: 40px;
    cursor: pointer;
  }

  label span {
    background: #fff;
    border-radius: 10px;
    height: 3px;
    margin: 7px 0;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  span:nth-of-type(1) {
    width: 50%;
  }

  span:nth-of-type(2) {
    width: 100%;
  }

  span:nth-of-type(3) {
    width: 75%;
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(1) {
    transform-origin: bottom;
    transform: rotatez(45deg) translate(10px, 4px);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(2) {
    transform-origin: top;
    transform: rotatez(-45deg);
  }

  input[type="checkbox"]:checked ~ span:nth-of-type(3) {
    transform-origin: bottom;
    width: 50%;
    transform: translate(18px, -10px) rotatez(45deg);
  }
}

header.show-nav {
  left: 0;
  .hamburger label span {
    background: $primary;
  }
}
