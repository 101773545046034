.company-page {
  .section-hero {
    .text-box {
      width: 741px;
      max-width: 100%;
      @media (max-width: 991px) {
        padding-right: 20px;
      }
      p {
        color: $white;
      }
    }
  }
  .section-who-we-are {
    padding: 129px 0 111px;
    @media (max-width: 1366px) {
      padding: 92px 0 91px;
    }
    .container {
      .row {
        align-items: center;
        .text-box {
          padding-right: 90px;
          p {
            line-height: 1.9;
          }
        }
      }
    }
  }
  .section-our-expertise {
    padding: 120px 0 217px;
    background-color: $primary;
    @media (max-width: 1366px) {
      padding: 92px 0 91px;
    }
    .row {
      align-items: center;
      &:first-child,
      &:nth-child(3) {
        .image-container {
          padding-right: 84px;
        }
      }
      &:nth-child(2) {
        .image-container {
          padding-left: 84px;
        }
      }
      &:not(:last-child) {
        margin-bottom: 109px;
        @media (max-width: 1366px) {
          margin-bottom: 70px;
        }
      }
    }
    .image-container {
      img {
        border-radius: 11px;
      }
    }
    h1,
    .subtitle {
      color: $white;
    }
    .text-box {
      padding-right: 54px;
    }
    .subtitle {
      line-height: 1.9;
    }
    .header-box-main {
      margin-bottom: 109px;
      @media (max-width: 1366px) {
        margin-bottom: 82px;
      }
    }
  }

  .section-get-to-know-us {
    background-color: $body-light;
    padding: 83px 0 96px;
    .header-box {
      margin-bottom: 87px;
    }
    .row {
      --bs-gutter-x: 67px;
    }
    .content-container {
      height: 100%;
    }
    .card {
      padding: 73px 61px 41px;
      border: none;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
      height: 100%;
      justify-content: space-between;
      .icon-container {
        width: 28px;
        height: 28px;
        position: relative;
        margin-bottom: 32px;
        margin-left: 17px;
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 200%;
          height: 200%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background-color: rgba(137, 137, 137, 0.1);
        }
      }

      .text-box {
        margin-bottom: 20px;
      }
    }
  }
}
