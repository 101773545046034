.page-faq {
  .hero-small {
    position: relative;
    height: 854px;
    .hero-conatiner {
      height: 100%;
      .image-container {
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
    .text-box {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 850px;
      max-width: 100%;
      h1 {
        margin-bottom: 17px;
      }
      p {
        font-size: 20px;
        line-height: 1.73;
        font-weight: 400;
        color: $white;
      }
    }
  }
  .section-accordion {
    .text-section {
      margin-top: -225px;
    }
    .row {
      align-items: center;
    }
    .text-box {
      p {
        font-size: 20px;
        font-weight: 400;
        line-height: 1.73;
        color: $primary;
      }
    }
    .accordion {
      padding: 41px 72px;
      transform: translateY(-225px);
      background-color: $white;
      border-radius: 20px;
      .accordion-item {
        border: none;
        &:not(:last-child) {
          border-bottom: 1px solid $text-muted;
        }
      }
      .accordion-header {
        padding: 30px 0 48px;

        button {
          font-size: 20px;
          font-weight: 500;
          color: #000;
          padding: 0;
        }
        .accordion-button:not(.collapsed) {
          background-color: transparent;
          box-shadow: none;
        }
        .accordion-button:focus {
          z-index: 3;
          border: None;
          outline: 0;
          box-shadow: none;
        }
        .accordion-button::after {
          flex-shrink: 0;
          width: 2.25rem;
          height: 2.25rem;
          margin-left: auto;
          content: "+";
          background-image: none;
          background-repeat: no-repeat;
          background-size: 2.25rem;
          transform-origin: center;
          font-size: 32px;
          transition: transform 0.2s ease-in-out;
        }
        .accordion-button:not(.collapsed)::after {
          transform: rotate(-45deg) scale(1.2);
        }
      }
      .accordion-body {
        font-size: 20px;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 35px;
        color: $text-sub;
      }
    }
  }
  .section-cta2 {
    margin-top: -225px;
  }
}
