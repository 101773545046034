.section-cta2 {
  position: relative;
  .content-container {
    padding: 72px 0 64px;
    text-align: center;
    color: $white;
    width: 850px;
    max-width: 100%;
    margin: 0 auto;
    @media (max-width: 1440px) {
      padding: 54px 0 29px;
    }
    @media (max-width: 1366px) {
      padding: 51px 0 25px;
    }
    h1,
    .subtitle {
      color: $white;
    }

    .header-box {
      line-height: 1;
      h1 {
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        .image-container {
          width: 200px !important;
          margin: 0 20px;
        }
      }
      margin-bottom: 25px;
      @media (max-width: 1440px) {
        margin-bottom: 16px;
      }
    }
    .text-box {
      margin-bottom: 25px;
      @media (max-width: 1440px) {
        margin-bottom: 19px;
      }
      p {
        margin-bottom: 0;
        line-height: 1.7;
        text-align: center;
      }
    }
    .button-container {
      @media (max-width: 1440px) {
        margin-bottom: 19px;
      }

      .btn-transparent {
        border: 1px solid $green;
        padding: 18px 94px;
        font-size: 20px;
        font-weight: 600;
        @media (max-width: 1440px) {
          padding: 10px 75px;
          font-size: 15px;
        }
      }
    }
  }
}
