.page-pricing {
  padding-top: 100px;
  background-color: $body-demo;
  @media (max-width: 1366px) {
    padding-top: 70px;
  }

  .section-pricing {
    padding: 68px 0;
    .header-box {
      // margin-bottom: 50px;
    }

    .controls-box {
      display: flex;
      .switch {
        margin-right: 16px;
        margin-left: 16px;
      }
      .input-group {
        flex-wrap: nowrap;
        width: auto;
        align-items: center;
        p {
          margin-bottom: 0;
        }
        .text-highlight {
          color: $primary;
        }
        .input-group-prepend {
          margin-right: 15px;
        }
        &:not(:last-child) {
          margin-right: 61px;
        }
        .form-control {
          display: inline-block;
          width: auto;
        }
        input.form-control {
          width: 100px;
          height: 35px;
          border: 1px solid $primary;
        }
      }
    }
  }
  .section-card-offer {
    padding-bottom: 68px;
    .row {
      --bs-gutter-x: 23px;
    }
    .content-container {
      background-color: $white;
      padding: 63px 53px;
      border-radius: 8px;
      position: relative;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease;
      text-align: center;
      @media (max-width: 768px) {
        margin-bottom: 30px;
      }
      .tag-container {
        width: 87px;
        position: absolute;
        top: 18px;
        right: 18px;
        opacity: 0;
        transition: all 0.3s ease 0.2s;
      }
      .package {
        font-size: 16px;
        font-weight: 500;
        color: $primary;
        margin-bottom: 9px;
      }
      .price {
        font-size: 45px;
        font-weight: 500;
        margin-bottom: 31px;
      }
      .text-muted {
        font-size: 16px;
        margin-bottom: 31px;
      }
      .button-container {
        width: 100%;
        margin-bottom: 31px;
      }

      .features-container {
        text-align: left;
        .feature-header {
          margin-bottom: 14px;
          .text-highlight {
            color: $primary;
          }
          .icon-container {
            width: 14px;
            margin-right: 12px;
            svg path {
              fill: $primary;
            }
          }
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            font-size: 16px;
            color: $text-muted;
            &:not(:last-child) {
              margin-bottom: 18px;
            }
          }
        }
      }

      // &:hover {
      //   background: $primary;
      //   .tag-container {
      //     opacity: 1;
      //   }
      //   p,
      //   h1,
      //   li {
      //     color: $white !important;
      //   }
      //   button {
      //     border: 1px solid $white !important;
      //   }
      //   .icon-container {
      //     svg path {
      //       fill: $white !important;
      //     }
      //   }
      // }
    }
    .content-container.popular {
      background: $primary;
      .tag-container {
        // opacity: 1;
      }
      p,
      h1,
      li {
        color: $white !important;
      }
      button {
        border: 1px solid $white !important;
      }
      .icon-container {
        svg path {
          fill: $white !important;
        }
      }
    }
    .content-container.standard {
      background: $standard-green;
      .tag-container {
        // opacity: 1;
      }
      p,
      h1,
      li {
        color: $white !important;
      }
      .button-container {
        border-radius: 8px;
        .overlay {
          background-color: $primary;
        }
        .btn-primary {
          background-color: transparent;
        }
        &:hover {
          .btn {
            color: $white !important;
          }
        }
      }
      .btn {
        border: none !important;
      }
      .icon-container {
        svg path {
          fill: $white !important;
        }
      }
    }
    [class*="col-"] {
      &:last-child {
        .content-container {
          background: $primary;
          .tag-container {
            // opacity: 1;
          }
          p,
          h1,
          li {
            color: $white !important;
          }
          .button-container {
            border: 1px solid $white !important;
            border-radius: 8px;
          }
          button {
            border: none !important;
          }
          .icon-container {
            svg path {
              fill: $white !important;
            }
          }
        }
      }
      &:nth-child(2) {
        .content-container {
          background: $standard-green;
          .tag-container {
            // opacity: 1;
          }
          p,
          h1,
          li {
            color: $white !important;
          }
          .button-container {
            border-radius: 8px;
            border: 1px solid $white !important;
            .overlay {
              background-color: $primary;
            }
            .btn-primary {
              background-color: transparent;
            }
            &:hover {
              .btn {
                color: $white !important;
              }
            }
          }

          .icon-container {
            svg path {
              fill: $white !important;
            }
          }
        }
      }
    }
  }

  .section-icons {
    padding: 63px 0;
    background-color: $white;
    .content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon-container {
        width: 37px;
        margin-bottom: 23px;
      }
      p {
        font-size: 20px;
        font-weight: 600;
        color: $primary;
      }
    }
  }
}
