.career-page {
  .section-be-a-part {
    padding: 257px 103px;
    @media (max-width: 1440px) {
      padding: 127px 103px;
    }
    @media (max-width: 991px) {
      padding: 100px 0;
    }
    .content-container {
      padding-left: 76px;
      @media (max-width: 991px) {
        padding-left: 0;
      }
      .text-box {
        margin-bottom: 10px;
      }
    }
  }
  .row {
    .col-* {
      :nth-child(2) {
        order: 1;
      }
    }
  }
  .section-slider {
    padding: 87px 0 151px;
    background-color: $body-light;
    .header-box {
      margin-bottom: 57px;
    }
    .item {
      border-radius: 42px;
      overflow: hidden;
      box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
      .container {
      }
    }
    .container {
      @media (min-width: 1920px) {
        max-width: 1462px;
      }
      .row {
        [class*="col-"] {
          padding: 0;
        }
      }
    }
    .main-image {
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      overflow: hidden;
      height: 695px;
      max-height: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .content-container {
      padding: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .detail {
        display: flex;
        align-items: center;
        .image-container {
          width: 114px;
          margin-right: 35px;
          height: 114px;
          img {
            border-radius: 50%;
            height: 100%;
            object-fit: cover;
          }
        }
        .identity {
          h5 {
            font-family: "Nunito-sans";
            font-size: 24px;
            font-weight: 800;
            color: $primary;
          }
          p {
            font-family: "Nunito-sans";
            font-size: 16px;
            font-weight: 600;
            color: $primary;
          }
        }
      }
    }
    .slick-next {
      position: absolute;

      top: -57px;

      width: 52px;
      height: 52px;
      right: 0;
      background-image: url("./Assets/images/arrow-left.png");
      &:before {
        content: "→";
        font-size: 52px;
        line-height: 1;
        font-weight: 400;
        opacity: 0.75;
        color: transparent;
      }
    }
    .slick-prev {
      position: absolute;

      top: -57px;

      width: 52px;
      height: 52px;
      left: calc(100% - 124px);
      background-image: url("./Assets/images/arrow-right.png");
      &:before {
        // content: "→";
        font-size: 52px;
        line-height: 1;
        font-weight: 400;
        opacity: 0.75;
        color: transparent;
      }
    }
  }
  .section-locations {
    background-color: $primary;
    padding: 122px 0;
    .row {
      &:not(:last-child) {
        margin-bottom: 179px;
      }
    }
    .text-box {
      margin-bottom: 35px;
    }
    h1,
    p,
    li {
      color: $white;
    }

    .image-container {
      img {
        border-radius: 8px;
      }
    }

    ul {
      margin: 0;
      // padding: 0;
      li {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }
  }
  .section-team {
    padding: 79px 0 152px;
    table {
      width: 100%;
      margin: 45px 0;
      th {
        font-size: 20px;
        line-height: 2.8;
        font-weight: 500;
        color: rgba($primary, 0.5);
      }
      td {
        font-size: 24px;
        line-height: 2.33;
        font-weight: 400;
        color: $primary;
        &:first-child {
          font-weight: 600;
          color: #697ba2;
        }
      }
      td,
      th {
        padding: 25px 9px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      }
    }
  }
  .section-cta2 {
    .header-box {
      display: flex;
      align-items: center;
      justify-content: center;
      .image-container {
        width: 46px;
        margin-right: 29px;
      }
    }
  }
}
