footer {
  padding: 98px 0 53px;
  @media (max-width: 1440px) {
    padding: 76px 0 43px;
  }

  @media (max-width: 1366px) {
    padding: 72px 0 20px;
  }
  .footer-top {
    padding: 0 0 50px;
    @media (max-width: 1440px) {
      padding: 0 0 59px;
    }
    @media (max-width: 1366px) {
      padding: 0 0 40px;
    }
  }
  .container {
    @media (min-width: 1366px) {
      max-width: 981px;
    }
    @media (min-width: 1440px) {
      max-width: 1031px;
    }
    @media (min-width: 1920px) {
      max-width: 1380px;
    }
  }
  .content-container {
    @media (max-width: 575px) {
      margin-bottom: 20px;
      text-align: center;
    }
    .logo-container {
      width: 156px;
      margin-bottom: 26px;
      @media (max-width: 1440px) {
        width: 118px;
        margin-bottom: 19px;
      }
      @media (max-width: 1366px) {
        width: 113px;
        margin-bottom: 19px;
      }
      @media (max-width: 575px) {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 19px;
      }
    }
    .text-box {
      h2 {
        font-family: "Nunito Sans", sans-serif;
        font-size: 24px;
        line-height: 1.5;
        font-weight: 400;
        color: $text-sub;
        @media (max-width: 1440px) {
          font-size: 18px;
        }
      }
    }
    p {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 35px;
      color: rgba(0, 0, 0, 0.41);
      @media (max-width: 1440px) {
        font-size: 17px;
        margin-bottom: 27px;
      }
    }
    .footer-nav {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
          font-size: 20px;
          color: $text-main;
          text-decoration: none;
          @media (max-width: 1440px) {
            font-size: 16px;
          }
          &:not(:last-child) {
            margin-bottom: 22px;
            @media (max-width: 1440px) {
              margin-bottom: 16px;
            }
          }
          a {
            font-size: 20px;
            color: $text-main;
            text-decoration: none;
            @media (max-width: 1440px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  .footer-icon-list {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
    @media (max-width: 575px) {
      justify-content: center;
    }
    .footer-icon-item {
      width: 48px;
      &:not(:last-child) {
        margin-right: 23px;
      }
    }
  }
  .copy {
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #1e3056;
    text-align: center;
    margin-bottom: 0;
    @media (max-width: 1440px) {
      font-size: 13px;
    }
  }
  .footer-contact {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      margin-bottom: 20px;
      font-size: 20px;
      @media (max-width: 1440px) {
        font-size: 16px;
      }
      .image-container {
        width: 20px;
        margin-right: 20px;
        svg {
          fill: #626262;
        }
        svg.no-fill {
          fill: none;
          stroke: #626262;
        }       
      }
    }
  }
}
.footer-dark {
  background-color: $primary;
  color: $white;
  p,
  li,
  a,
  h2 {
    color: $white !important;
  }
  .logo-dark {
    display: block;
  }
  .logo-normal {
    display: none;
  }
  svg.no-fill {
    fill: none !important;
    stroke: $white !important;
  }
  svg path {
    fill: $white !important;
  }
}
