.btn {
  position: relative;
  overflow: hidden;
  // font-family: "";
  font-size: 1.6rem;

  &:active {
    box-shadow: none;
  }
  // &::before {
  //   content: "";
  //   display: inline-block;
  //   background-color: $green;
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   left: -100%;
  //   top: 0;
  //   transition: all 0.3s ease;
  // }
  // &:hover {
  //   color: $primary !important;
  //   box-shadow: none;
  //   &::before {
  //     left: 0;
  //   }
  // }
}
.button-container {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: fit-content;
  span.overlay {
    position: absolute;
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    top: 0;
    background: $green;
    left: -100%;
    transition: all 0.5s cubic-bezier(0.35, 1.77, 0.97, 0.83);
  }
  &:hover {
    span.overlay {
      left: 0;
    }
    .btn {
      color: $primary !important;
      background-color: transparent;
      border: none;
    }
  }
}

.btn-transparent {
  padding: 18px 53px;
  background-color: transparent;
  border: 1px solid $white;
  border-radius: 4px;
  color: $white;
  &:hover {
    border-color: $green;
  }
  @media (max-width: 1440px) {
    padding: 13px 36px;
  }
  @media (max-width: 1366px) {
    padding: 13px 34px;
  }
}
.btn-filled {
  padding: 18px 34px;
  background-color: $white;
  border: 1px solid $white;
  border-radius: 4px;
  color: $primary;
  font-weight: 600;
  @media (max-width: 1440px) {
    padding: 13px 17px;
  }
  @media (max-width: 1366px) {
    padding: 13px 17px;
    font-size: 13px;
  }
}
.btn-dark {
  padding: 18px 53px;
  background-color: transparent;
  border: 1px solid $green;
  border-radius: 4px;
  color: $primary;
  font-weight: 600;
  @media (max-width: 1440px) {
    padding: 14px 46px;
  }
  @media (max-width: 1366px) {
    padding: 13px 27px;
  }
}

.btn-bold {
  padding: 18px 15px;
  font-size: 15px;
  font-weight: 600;
  color: $primary;
}
.btn-fixed-width {
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-primary {
  background-color: $primary;
  padding: 18px 25px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  border-radius: 8px;
  box-shadow: 0 4px 4px 4px rgba(#000000, 0.25);
  color: $white;
}
.button-container {
  border-radius: 4px;
}

.btn-primary {
  &:hover,
  &:focus,
  &:active {
    background-color: $primary;
  }
}
