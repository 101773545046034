.page-blogs {
  padding-top: 100px;
  .section-main {
    padding: 87px 0 0;
    margin-bottom: 42px;
    .image-container {
      height: 100%;
      img {
        width: 200px;
        height: 100%;
        object-fit: cover;
      }
    }
    .row {
      --bs-gutter-x: 0;
    }
    .image-container {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      overflow: hidden;
    }
    .content-container {
      background-color: $blog-card;
      padding: 72px 50px;
      height: 100%;
      border-radius: 8px;
      position: relative;
      .text-muted {
        font-size: 16px;
        color: rgba($primary, 0.5);
        margin-bottom: 27px;
      }
      .header-box {
        margin-bottom: 27px;
        width: 90%;
        max-width: 100%;
        h2 {
          font-size: 36px;
          font-weight: 700;
          line-height: 1.27;
          color: $primary;
        }
      }
      .date {
        position: absolute;
        left: 50px;
        bottom: 70px;
        @media (max-width: 1919px) {
          bottom: 10px;
        }
      }
    }
    .header-box {
      margin-bottom: 88px;
    }
  }
  .section-news {
    .row {
      --bs-gutter-x: 42px;
      --bs-gutter-y: 42px;
      .content-container {
        height: 100%;
      }
    }
    .row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
    .row > [class*="col-"] {
      display: flex;
      flex-direction: column;
    }
    .content-container {
      background-color: $blog-card;
      position: relative;
      border-radius: 8px;
    }
    .text-box {
      background-color: $blog-card;
      padding: 17px 23px;

      min-height: 217px;
      .subtitle {
        font-size: 16px;
        color: rgba($primary, 0.5);
        margin-bottom: 14px;
      }
      h4 {
        font-size: 24px;
        font-weight: 700;
      }
      .date {
        font-size: 16px;
        color: rgba($primary, 0.5);
        position: absolute;
        left: 23px;
        bottom: 17px;
      }
    }
    .bg-white {
      background-color: $white;
    }
    .dh-cta {
      padding: 51px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: $primary;
      color: $white;
      text-align: center;
      h2{
        color: $white;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      p {
        color: $white;
      }

      p {
        margin: 25px 0;
        line-height: 2.125;
      }
    }
  }
  .loadmore.text-box {
    margin-top: 90px;
    padding: 0;
    min-height: auto;
    p {
      text-align: center;
      display: inline-block;
      cursor: pointer;
    }
    p.end-of-section {
      cursor: no-drop;
    }
  }
}
