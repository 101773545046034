.policy-page {
  .policy-container {
    padding: 50px 0;
  }
  .header-box {
    margin-bottom: 118px;
  }
  .text-box {
    h3 {
      margin-bottom: 20px;
    }
  }
  b {
    color: $primary;
  }
  p {
    margin-bottom: 20px;
  }
}
