.section-cta {
  padding: 104px 0 90px;
  border-bottom: 1px solid #c5c5c5;
  @media (max-width: 1440px) {
    padding: 78px 0 65px;
  }
  @media (max-width: 1366px) {
    padding: 75px 0 60px;
  }
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-container {
      width: 38px;
      margin-right: 22px;
      @media (max-width: 1440px) {
        width: 29px;
      }
      @media (max-width: 1366px) {
        width: 28px;
      }
    }
    .text-container {
      h2 {
        font-size: 24px;
        font-weight: 500;
        margin: 0;
        @media (max-width: 1440px) {
          font-size: 18px;
        }
        @media (max-width: 1366px) {
          font-size: 18px;
        }
      }
    }
  }
}
