.section-why {
  padding: 179px 0 90px;
  @media (max-width: 1440px) {
    padding: 142px 0 70px;
  }
  @media (max-width: 1366px) {
    padding: 136px 0 70px;
  }
  @media (max-width: 768px) {
    padding: 46px 0 40px;
  }
  .header-box {
    margin-bottom: 26px;
    @media (max-width: 1440px) {
      margin-bottom: 7px;
    }
    @media (max-width: 1366px) {
      margin-bottom: 5px;
    }
    h1 {
      color: $text-main;
    }
  }
  .text-box {
    margin-bottom: 26px;
    @media (max-width: 1440px) {
      margin-bottom: 21px;
    }
    @media (max-width: 1366px) {
      margin-bottom: 21px;
    }
    p {
      color: $text-sub;
    }
  }
}
