/**
 * Basic typography style for copy text
 */
// body {
//   color: $text-color;
//   font: normal 125% / 1.4 $text-font-stack;
// }

h1 {
  font-size: 6.4rem;
  font-weight: 700;
  line-height: 1.5;
  margin: 0;
  color: $text-main;
  @media (max-width: 1440px) {
    font-size: 5.7rem;
  }
  @media (max-width: 1366px) {
    font-size: 5.2rem;
  }
}
h2 {
  font-size: 36px;
  line-height: 1;
  font-weight: 700;
  color: $text-main;
}
h3 {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 700;
  color: $text-main;
}
h5 {
  font-size: 20px;
  font-weight: 500;
  color: $text-main;
}
.subtitle {
  font-size: 20px;
  line-height: 1.5;
  font-weight: 400;
  color: $text-sub;
  @media (max-width: 1440px) {
    font-size: 16px;
  }
  @media (max-width: 1366px) {
    font-size: 15px;
  }
}
p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  color: $text-normal;
  text-align: justify;
}

.label-dark {
  font-size: 16px;
  text-decoration: underline;
  color: $primary;
  font-weight: 600;
}

.highlight-primary {
  color: $primary2;
}
