.page-contact {
  .hero-small {
    height: 544px;
  }

  .contact-form {
    padding: 66px 50px 15px;
    border-radius: 15px;
    border: 1px solid $color-error;
    background-color: $white;
    transform: translateY(-130px);

    .header-box {
      margin-bottom: 62px;
    }

    .container {
      label {
        margin-bottom: 60px;

        @media (max-width: 1367px) {
          margin-bottom: 30px;
          font-size: 13px;
        }

        p {
          @media (max-width: 1366px) {
            font-size: 12px;
          }
        }

        input,
        select {
          @media (max-width: 1366px) {
            font-size: 13px;
          }
        }
      }
    }

    .PhoneInput {
      .PhoneInputCountry {
        margin-right: 15px;
      }
    }

    .button-container {}
  }

  .section-contact-us {
    .content-container {
      @media (max-width: 991px) {
        padding-bottom: 30px;
      }
    }

    .item {
      &:not(:last-child) {
        margin-bottom: 42px;
      }

      h4 {
        font-size: 24px;
        font-weight: 700;
        color: $primary;
        line-height: 2.71;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          font-size: 20px;
          color: $text-muted;
          line-height: 1.8;
          display: flex;

          .image-container {
            width: 2opx;
            margin-right: 20px;
          }
        }
      }
    }

    .item.social-media {
      ul {
        display: flex;

        li {
          .image-container {
            width: 50px;
          }

          &:not(:last-child) {
            margin-right: 15px;
          }
        }
      }
    }
  }
}