.career-details-page {
  padding-top: 100px;
  .section-career-detail {
    .header-container {
      padding: 50px 205px 54px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.07);
      @media (max-width: 991px) {
        padding: 40px 0;
      }
      .text-muted {
        margin-bottom: 10px;
        font-size: 16px;
        color: rgba($primary, 0.5);
      }
      .header-box {
        margin-bottom: 10px;
        h1 {
          font-size: 40px;
          font-weight: 600;
          color: $primary;
        }
      }
      .subtitle {
        font-size: 24px;
        line-height: 2.8;
        color: rgba($primary, 0.5);
      }
    }
    .body-container {
      padding: 23px 205px 148px;
      @media (max-width: 991px) {
        padding: 40px 0;
      }
      .header-box {
        margin-bottom: 15px;
        h3 {
          font-size: 24px;
          line-height: 2.8;
          color: $primary;
          font-weight: 600;
        }
      }
      .text-box {
        margin-bottom: 41px;
      }
    }
  }
}
