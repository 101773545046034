.page-ourTeam {
  padding-top: 100px;
  .section-teams {
    padding: 86px 0;
    .header-box {
      margin-bottom: 100px;
    }
    .container {
      .row {
        &:not(:last-child) {
          margin-bottom: 72px;
        }
        &:last-child {
          .content-container {
            margin-top: 72px;
          }
        }
        --bs-gutter-x: 120px;

        justify-content: center;
        .content-container {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    .content-container {
      position: relative;

      .image-container {
        width: 350px;
        height: 350px;
        margin-bottom: 33px;
        img {
          object-fit: cover;
          height: 100%;
          object-position: top;
          border-radius: 20px;
        }
      }
      .text-box {
        text-align: center;
        h3 {
          font-weight: 500;
        }
        h3.designation {
          font-style: italic;
          color: $header-muted;
          margin-bottom: 23px;
        }
        button {
          background-color: transparent;
          border: none;
        }
        .bio-trigger {
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            margin-bottom: 0;
            margin-right: 3px;
            color: $primary;
            font-weight: 600;
          }

          .icon-container {
            font-weight: 700;
          }
        }
      }
    }
  }
}
.tooltip-inner {
  background-color: $primary;
  width: 63%;
  max-width: calc(100% - 15px);
  margin: 0 auto;
  text-align: left;
  padding: 42px;
  border-radius: 20px;
  transform: translateX(22px);
  @media (max-width: 1440px) {
    width: 87%;
  }
}

.bio-box {
  .content-container {
    display: flex;
    .left {
      padding: 20px;
      width: 267px;
      border-right: 1px solid $white;
      display: flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: space-between;
      h2 {
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 17px;
        color: $white;
      }
      h3 {
        font-size: 24px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.5);
      }
      .social-account {
        a {
          display: flex;
          align-items: center;
          img {
            width: 36px;
            margin-right: 15px;
          }
        }
        h4 {
          color: $white;
          font-size: 16px;
          font-weight: 600;
          margin: 0;
        }
      }
    }
    .right {
      padding: 20px 0 20px 61px;
      .header-box {
        margin-bottom: 10px;
        h3 {
          color: $white;
          font-size: 24px;
          font-weight: 500;
        }
        .text-box {
          text-align: left;
          p {
            color: $white;
            font-size: 18px;
            line-height: 2.11;
            font-weight: 400;
          }
        }
      }
    }
  }
}
