.page-product {
  padding-top: 100px;
  .section-product-main {
    padding: 70px 0;
    .header-box {
      margin-bottom: 70px;
      width: 700px;
      max-width: 100%;
      margin: 0 auto;
      margin-bottom: 70px;
    }
    .product-category-container {
      @media (min-width: 1920px) {
        max-width: 1383px;
      }
      .row {
        --bs-gutter-x: 30px;
        --bs-gutter-y: 30px;
      }
    }
    .card {
      padding: 63px 31px 53px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      border: none;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      min-height: 259px;
      .icon-container {
        width: 60px;
        margin-bottom: 16.38px;
        height: 70px;
      }
      h5 {
        margin-bottom: 23px;
      }
    }
  }
  .section-alternate {
    .alternate {
      &:nth-child(2n) {
        background-color: rgba($alternate-bg-dark, 0.13);
      }
    }
    .container {
      .row {
        align-items: center;
        padding: 110px 0;
        @media (max-width: 1440px) {
          padding: 60px 0;
        }
      }
    }
  }
}
