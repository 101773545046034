.section-help {
  padding: 59px 0 230px;
  @media (max-width: 1440px) {
    padding: 51px 0 178px;
  }
  @media (max-width: 1366px) {
    padding: 44px 0 174px;
  }
  @media (max-width: 768px) {
    padding: 40px 0;
  }
}
