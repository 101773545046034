.apply-form {  
  h2 {
    font-size: 36px;
    font-weight: 400;
    color: $primary;
    margin-bottom: 50px;
  }
  h4 {
    font-size: 24px;
    font-weight: 400;
    color: $text-secondary;
  }

  .modal-body {
    padding: 20px 0 !important;
    .container {
      .row {
        --bs-gutter-x: 44px;
      }
    }
    .form {
      label {
        margin-bottom: 15px;
      }
      [class*="col"] {
        margin-bottom: 20px;
      }
      .phone-number {
        display: flex;

        .PhoneInput {
          display: flex;
          width: 100%;
        }
      }
    }
    .icon-container {
      width: 25px;
      cursor: pointer;
    }
    .image-container {
      width: 94px;
      margin-bottom: 37px;
    }
    h3 {
      font-weight: 400;
      color: $text-secondary;
    }
  }
}
