.page-demo {
  padding-top: 100px;
  @media (max-width: 1366px) {
    padding-top: 76px;
  }
  .section-form-video {
    .form-container {
      padding: 76px 0;
      @media (max-width: 1920px) {
        margin-left: calc((100vw - 1618px) / 2);
      }
      @media (max-width: 1919px) {
        margin-left: calc((100vw - 1251px) / 2);
      }
      @media (max-width: 1366px) {
        margin-left: calc((100vw - 1184px) / 2);
        margin-right: -62px;
      }
      @media (max-width: 1200px) {
        margin-left: 0;
      }

      .container {
        .row {
          --bs-gutter-x: 32px;
          @media (max-width: 1366px) {
            --bs-gutter-x: 18px;
          }
        }
      }
      form {
        .header-box {
          margin-bottom: 51px;
          text-align: center;
        }
        label {
          margin-bottom: 32px;
        }
      }
    }
    .sec-vid {
      height: 100%;
      padding: 164px 134px;
      background-size: cover;
      @media (max-width: 585px) {
        padding: 46px 57px;
      }
      @media (max-width: 375px) {
        padding: 20px;
      }
      .header-box {
        text-align: center;
        h1 {
          color: $white;
          margin-bottom: 28px;
        }
        h2 {
          color: $white;
          margin-bottom: 28px;
        }
      }
      .text-box {
        text-align: center;
        p {
          color: $white;
        }
        margin-bottom: 56px;
      }
      .iframe-container {
        // border-radius: 20px;
        border: 2px solid $white;
      }
    }
  }
}
