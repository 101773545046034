.page-navigation {
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  padding: 53px 0 41px;

  span {
    font-size: 15px;
    font-weight: 500;
    color: $text-sub;
  }

  span {
    &:first-child {
      color: $text-main;
      text-decoration: underline;
    }
  }

  a {
    text-decoration: underline;
  }
}

.section-hero.hero-small {
  .text-box {
    top: 209px;
  }
}

label {
  width: 100%;
}

.form-control {
  padding: 16px 10px;

  @media (max-width: 1440px) {
    padding: 9px 5px;
  }
}

.card-container {
  border-radius: 15px;

  .row {
    --bs-gutter-x: 42px;
    --bs-gutter-y: 42px;

    .content-container {
      height: 100%;
      border-radius: 15px;
    }
  }

  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .row>[class*="col-"] {
    display: flex;
    flex-direction: column;
  }

  .content-container {
    background-color: $blog-card;
    position: relative;
  }

  .text-box {
    background-color: $blog-card;
    padding: 17px 23px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    min-height: 217px;

    .subtitle {
      font-size: 16px;
      color: rgba($primary, 0.5);
      margin-bottom: 14px;
    }

    h4 {
      font-size: 24px;
      font-weight: 700;
    }

    .date {
      font-size: 16px;
      color: rgba($primary, 0.5);
      position: absolute;
      left: 23px;
      bottom: 17px;
    }
  }
}

.equal-height {
  .row {
    --bs-gutter-x: 42px;
    --bs-gutter-y: 42px;

    .content-container {
      height: 100%;
    }
  }

  .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .row>[class*="col-"] {
    display: flex;
    flex-direction: column;
  }
}

.section-common {
  .header-box {
    margin-bottom: 26px;

    @media (max-width: 1440px) {
      margin-bottom: 7px;
    }

    @media (max-width: 1366px) {
      margin-bottom: 5px;
    }

    h1 {
      color: $text-main;
    }
  }

  .text-box {
    margin-bottom: 26px;

    @media (max-width: 1440px) {
      margin-bottom: 21px;
    }

    @media (max-width: 1366px) {
      margin-bottom: 21px;
    }

    p {
      color: $text-sub;
    }
  }
}

.modal-dialog {
  .modal-content {
    padding: 28px 74px;

    .modal-header,
    .modal-body {
      padding: 0;
    }

    .modal-header {
      padding-bottom: 14px;
      border-bottom: 1px solid #aeaeae;
    }

    .modal-body {
      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          border-bottom: 1px solid #aeaeae;
        }
      }

      .content-container {
        padding: 34px 0;

        p {
          font-size: 13px;
        }

        label {
          width: fit-content;
          display: flex;
          align-items: center;
          font-size: 13px;
          color: $primary;
          margin-right: 24px;

          input {
            color: $primary;
            margin-left: 10px;
          }
        }
      }

      .btn {
        font-size: 13px;
      }

      .modal-controls {
        padding-top: 34px;
      }
    }
  }
}

.custom-control-input {
  background-color: $primary;
}

.modal-70w {
  width: 70vw;
  padding: 6vw 0 !important;
}

.stikcy-header-yes {
  padding-top: 100px;
}

.sc-bczRLJ.kiHXWb {
  max-width: 100% !important;
  height: 120px !important;
  border-color: rgba($primary, 0.5) !important;
}

.sc-bczRLJ.dGSgsW {
  max-width: 100% !important;
  height: 120px !important;
  border-color: rgba($primary, 0.5) !important;
}


main {
  min-height: 580px;
}

.tooltip .tooltip-arrow {
  z-index: -1;
}

.bs-tooltip-bottom .tooltip-arrow {
  top: 13px;
}

.tooltip.show {
  opacity: 0.9;
  top: 26px !important;
  left: -35px !important;
}

.tooltip .tooltip-arrow::before {
  background-image: url("./Assets/images/tooltip-header.png");
  width: 90px;
  height: 40px;
}

.modal-small {
  width: 420px;
}

.PhoneInput {
  .PhoneInputCountry {
    width: 60px;
    margin-right: 44px;
    flex-shrink: 0;
    padding: 8px 10px;
    border: 1px solid #ced4da;
    display: flex;
    justify-content: space-around;

    &:focus,
    &:focus-visible {
      border-color: rgba(3, 45, 66, 0.8);
      box-shadow: 0 0 0 0.25rem rgbs(13, 110, 253, 25%) !important;
      outline: none;
    }
  }

  input {
    flex-grow: 1;
    padding: 11px 10px;
    border: 1px solid #ced4da;
    font-size: 16px;
    color: $input-text;

    &:focus,
    &:focus-visible {
      border-color: rgba(3, 45, 66, 0.8);
      box-shadow: 0 0 0 0.25rem rgbs(13, 110, 253, 25%) !important;
      outline: none;
    }
  }
}