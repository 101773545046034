.section-hero {
  position: relative;
  height: 1030px;
  background-size: cover;
  @media (max-width: 1440px) {
    height: 774px;
  }
  @media (max-width: 1366px) {
    height: 734px;
  }
  .text-box {
    position: absolute;
    top: 34.7rem;
    @media (max-width: 1440px) {
      top: 29.65rem;
    }
    @media (max-width: 1366px) {
      top: 28.5rem;
    }

    h1 {
      margin: 0;
      color: $white;
      font-weight: 500;
      line-height: 1.7;
      span {
        font-weight: 700;
      }
    }
    .button-container {
      margin-top: 26px;
      @media (max-width: 1440px) {
        margin-top: 21px;
      }
      .btn {
        border: 1px solid $green;
        border-radius: 4px;
        color: $white;
        padding: 14px 59px;
        font-size: 20px;
        font-weight: 600;
        @media (max-width: 1440px) {
          padding: 9px 38px;
          font-size: 16px;
          font-weight: 600;
        }
        @media (max-width: 1366px) {
          padding: 9px 31px;
        }
      }
    }
  }
}
