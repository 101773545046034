// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
body {
  box-sizing: border-box;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @media (max-width: 1656px) {
    font-size: 53.9%;
  }
}

body {
  font-size: 1.3rem;
  font-family: "Poppins", sans-serif;
  line-height: 1.4;
  font-weight: 400;
  // background-color: $body;
  margin-top: -3px;
}

// button,
// input,
// optgroup,
// select,
// textarea {
//   // font-family: "Nexa", "NexaRegular";
// }

img {
  width: 100%;
  height: auto;
}

main {
  // position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

body.menu-opened {
  height: 100%;
  overflow-y: hidden;
}

header {
  z-index: 999;
  position: absolute;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

  .container {
    .row {
      align-items: center;
    }
  }
}

.logo-dark {
  display: none;
}

.is-sticky {
  position: fixed;
  z-index: 9999;
  box-shadow: 0 4px 11px 0 rgba(205, 205, 205, 0.25);
  background-color: $white;

  .logo-normal {
    display: none;
  }

  .logo-dark {
    display: block;
  }

  .nav-list {
    .nav-item {
      a {
        color: $primary  !important;
      }
    }
  }

  .btn-transparent {
    border: 1px solid $green;
    color: $primary;
  }

  .btn-filled {
    background-color: $primary;
    color: $white;
  }
}

.container {
  @media (min-width: 1440px) {
    max-width: 1297px;
  }

  @media (min-width: 1920px) {
    max-width: 1618px;
  }
}

.container-small {
  @media (min-width: 1920px) {
    max-width: 1440px;
  }

  // @media (max-width: 1656px) {
  //   max-width: 84.37vw;
  // }
}

.container-narrow {
  @media (max-width: 1920px) {
    max-width: 1224px;
  }
}

.type::after {
  content: "|";
  animation: cursor 3.1s infinite step-start;
}

@keyframes cursor {
  50% {
    opacity: 0;
  }
}

input.form-control,
select.form-control,
textarea.form-control,
.form-select {
  font-family: "poppins", "sans-serif";
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  color: rgba($primary, 0.25);
  color: $input-text;
}

iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

/* The slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: $primary;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

a {
  text-decoration: none;
  color: rgb(33, 37, 41);

  &:hover,
  &:active {
    color: rgb(33, 37, 41);
  }
}

html {
  scroll-behavior: smooth;
}

.field-error {
  label {
    color: red;
  }

  p {
    color: red;
  }

  input,
  select {
    border-color: red !important;
  }

  .form-control,
  textarea {
    color: red;
    border: 1px solid red;

    &:focus {
      border-color: red;
    }
  }

  .sc-bczRLJ.kiHXWb {
    border-color: red !important;
  }
}

.form-control {
  &:focus {
    border-color: rgba($primary, 0.8);
  }
}

form {
  p.error {
    color: red;
    margin-top: 10px;
    // font-style: italic;
    font-weight: 400;
  }
}

.sc-bczRLJ.kiHXWb {
  justify-content: center;
}

.sc-dkzDqf.fVfcRr {
  justify-content: flex-start;
  flex-grow: 0;
}

.sc-dkzDqf.kTMxfv {
  justify-content: flex-start;
  flex-grow: 0;
}

// a {
//   h1,
//   h2,
//   h3,
//   h4,
//   h5,
//   h6,
//   p,
//   span,
//   div {
//     text-decoration: none;
//   }
// }

// * {
//   text-decoration: none;
// }

.modal-header {
  align-items: baseline;
}

.modal-content {
  border-radius: 8px;
}

:root {
  --PhoneInputCountryFlag-aspectRatio: 1.333;
}

// p {
//   -webkit-hyphens: auto;
//   -moz-hyphens: auto;
//   -ms-hyphens: auto;
//   hyphens: auto;
//   word-break: break-all;
// }

.modal-small.modal-dialog {
  top: 100px;

  .modal-content {
    padding: 32px 24px;
    text-align: center;
  }
}

.modal-bottom {
  bottom: -17px;
  width: 100%;
  max-width: 100%;
  position: absolute;
}

.cookie-bottom {
  .row {
    align-items: center;
    --bs-gutter-x: 40px;
  }

  p {
    margin: 0;
    font-size: 16px !important;
  }

  .button-group {
    display: flex;
    align-items: center;

    @media screen and (max-width:1000px) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 10px;
    }

    .button-container {
      margin-right: 20px;

      .btn {
        padding: 10px 15px;
      }

      .btn-filled {
        padding: 10px 24px;
        background-color: #032d42;
        color: #fff;
      }

      &:hover {
        .btn-filled {
          color: #032d42 !important;
          background-color: transparent;
          border: none;
        }
      }
    }

    .text-underline {
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }

    .icon-container {
      border: 1px solid $primary;
      color: $primary;
      font-size: 50px;
      border-radius: 50%;
      margin: 0;
      line-height: 0.6;
      padding: 10px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: $primary;
        color: $white;
      }
    }
  }
}