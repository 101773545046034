.section-ai {
  padding: 90px 0 90px;
  @media (max-width: 1440px) {
    padding: 70px 0;
  }
  @media (max-width: 1366px) {
    padding: 62px 0;
  }
  @media (max-width: 768px) {
    padding: 40px 0;
  }
  .row {
    align-items: center;
  }
}
