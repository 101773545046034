.page-blog-details {
  padding-top: 100px;
  .header-box {
    margin-bottom: 55px;
    h2 {
      font-size: 40px;
      font-weight: 600;
      margin: 10px 0;
    }
  }
  .blog-content {
    .image-container-main {
      border-radius: 15px;
      margin-bottom: 48px;
      img {
        border-radius: 15px;
      }
    }
    .content-container {
      display: flex;
      align-items: center;
      .social-media-container {
        width: 148px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        align-items: flex-start;
        p.text-muted {
          margin-bottom: 29px;
        }
        ul {
          display: flex;
          align-items: center;
          flex-direction: column;
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            &:not(:last-child) {
              margin-bottom: 29px;
            }
          }
          .icon-container {
            width: 15px;
            svg {
              filter: opacity(0.5);
            }
          }
        }
      }
    }
  }
  .section-related-articles {
    padding: 80px 0;
    .content-container {
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
