// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
// /// @type List
// $text-font-stack: "Open Sans", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif !default;

// /// Code (monospace) font family
// /// @type List
// $code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Monaco", monospace !default;

// /// Copy text color
// /// @type Color
// $text-color: rgb(34, 34, 34) !default;

// /// Main brand color
// /// @type Color
// $brand-color: rgb(229, 0, 80) !default;

// /// Light grey
// /// @type Color
// $light-grey: rgb(237, 237, 237) !default;

// /// Medium grey
// /// @type Color
// $mid-grey: rgb(153, 153, 153) !default;

// /// Dark grey
// /// @type Color
// $dark-grey: rgb(68, 68, 68) !default;

// /// Container's maximum width
// /// @type Length
// $max-width: 1180px !default;

// /// Breakpoints map
// /// @prop {String} keys - Keys are identifiers mapped to a given length
// /// @prop {Map} values - Values are actual breakpoints expressed in pixels
// $breakpoints: (
//   "small": 320px,
//   "medium": 768px,
//   "large": 1024px,
// ) !default;

// /// Relative or absolute URL where all assets are served from
// /// @type String
// /// @example scss - When using a CDN
// ///   $base-url: 'http://cdn.example.com/assets/';
// $base-url: "/assets/" !default;
$body: #e5e5e5;
$body-light: #fcfcfc;
$body-demo: #fbfbfb;
$white: #fff;
$primary: #032d42;
$primary2: #004db1;
$green: #9bcd9b;
$text-main: #1e3056;
$text-sub: #697ba2;
$text-normal: #808285;
$color-error: #f86a61;
$text-muted: #666666;
$header-muted: #5f5f5f;
$blog-card: #f6f6f6;
$alternate-bg-dark: #d9d9d9;
$standard-green: #09a953;
$text-secondary: #414141;
$text-tertiary: #1b1b1b;
$input-text: #636363;
// $heading_dark: #454545;
// $paragraph_dark: #585858;
// $secondary: #f77e0b;
// $grey_bg: #f2f2f2;
// $text-highlight: #87330c;

// $color-primary: #37aeaa;
// $color-grey-dark: #b0b0b0;
// $color-grey-light: #dcdcdc;
// $color-grey-lighter: #f3f4f8;
// $black: #000;
